<template>
  <div class="container m-container-only-s result-container">
    <div class="payment-result">
      <div class="header">
        <h1>{{ $t("planes.thx_payment") }}</h1>
      </div>

      <div class="dl">
        <div class="dt">{{ $t("shop.order_number") }}</div>
        <div class="dd">{{ orderNumber }}</div>
      </div>
      <!-- <div class="dl">
        <div class="dt">{{$t("shop.confirmation_code")}}</div>
        <div class="dd">{{ shopPaidCode }}</div>
      </div> -->
      <div class="dl">
        <div class="dt">{{ $t("shop.amount") }}</div>
        <div class="dd">
          <strong
            ><template v-if="shopPaidInfo.position === 'start'">{{
              shopPaidInfo.currency
            }}</template>
            {{ shopPaidInfo.price }}
            <template v-if="shopPaidInfo.position === 'end'">{{
              shopPaidInfo.currency
            }}</template>
            <template v-else>₽</template></strong
          >
        </div>
      </div>

      <p class="info">{{ $t("shop.soon_attendant") }}</p>

      <div class="footer">
        <a @click.prevent="cancelWindow" class="button pointer">{{
          $t("shop.close")
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import events from "@/mixins/events";

export default {
  name: "SuccessPaymentShop",
  //   props: ["totalPrice"],
  mixins: [events],
  props: {
    orderNumber: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    ...mapState("shop", {
      shopPaidInfo: state => {
        return state.shopPaidInfo;
      },
      shopPaidCode: state => {
        return state.shopPaidCode;
      }
    })
  },
  methods: {
    ...mapMutations("shop", {
      setEntity: "SET_ENTITY"
    }),
    ...mapMutations("order", {
      setOrderEntity: "SET_ENTITY"
    }),
    cancelWindow() {
      this.setEntity(["shopPaidInfo", {}]);
      this.setEntity(["shopPaidCode", ""]);
      this.setEntity(["shopPaidSuccess", false]);

      this.setOrderEntity(["orderNumber", ""]);

      this.$router.push("/shop");
    }
  }
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
