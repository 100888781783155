<template>
  <div class="modal default-modal modal-open">
    <div class="table">
      <div class="table-cell">
        <div class="modal-container">
          <a
            href="#"
            class="close inner"
            @click.prevent="cancelPopup()"
          ></a>
          <div class="modal-content">
            <h3><strong>Предупреждение</strong></h3>
            <div>
              <p class="modal-content-text">
                В вашей корзине находятся товары с возрастным ограничением 18+
              </p>
            </div>
            <div><p class="modal-content-text">Вы подтверждаете, что Вам исполнилось 18 лет+</p></div>

            <div class="buttons">
              <input
                type="submit"
                class="submit cancel"
                name="cancel"
                value="Отменить"
                @click.prevent="cancelOrder()"
              />
              <input
                type="submit"
                class="submit"
                name="enter"
                value="Подтвердить"
                @click.prevent="confirmEndSendOrder()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "isHave18question",
  methods: {
    ...mapMutations("shop", {
      setEntity: "SET_ENTITY"
    }),
    confirmEndSendOrder(){
      this.setEntity(["isHave18", true]);
      this.$emit("sendOrder");
    },
    cancelPopup(){
      this.setEntity(["isHaveAlcohol", false]);
    },
    cancelOrder(){
      this.setEntity(["isHaveAlcohol", false]);

      this.$emit("cancelOrder");

    }
  }
};
</script>

<style scoped></style>
