<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-default section-shop">
      <div v-if="!shopPaidSuccess" class="container m-container-only-s cart-container">
        <div class="product-cart">
          <div class="header" v-if="card.length">
            <h1>{{ $t("shop.card") }}</h1>
            <a href="#" v-if="!skyShop.find(item => item.state === 'processing')" @click.prevent="clearCard"><svg
                aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash fa-w-14 fa-2x">
                <path fill="currentColor"
                  d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"
                  class=""></path>
              </svg>
              <span>{{ $t("shop.clear_card") }}</span></a>
          </div>
          <div class="header" v-else>
            <h1>{{ $t("shop.card_empty") }}</h1>
            <router-link to="/shop" class="button">{{
              $t("shop.start_fill_card")
              }}</router-link>
          </div>

          <div class="cart-row" v-for="item in computedCard" :key="item.productId">
            <div :class="item.category === 'alcoholic_beverages' ? 'cart-row-logo' : ''
              ">
              <img :src="item.image" alt="" />
            </div>
            <div>{{ item.title }}</div>
            <div class="align-right">
              <template v-if="item.price.position && item.price.position === 'start'">{{ item.price.currency
                }}</template>
              {{ item.price.amount }}
              <template v-if="item.price.position && item.price.position === 'end'">{{ item.price.currency }}</template>
              <template v-else>₽</template>
            </div>
            <div class="total-price">
              <span>{{ $t("shop.count_product") }}</span>
              <p class="amount">
                <span class="minus" v-if="!isOrderProcessing" @click.prevent="
                  changeAmount({
                    productId: item.product_id,
                    action: 'DECREMENT_AMOUNT_CARD'
                  })
                  "></span>
                <b>{{ item.amount }}</b>
                <span class="plus" v-if="!isOrderProcessing" :class="{
                  disabled: parseInt(item.volume) <= parseInt(item.amount)
                }" @click.prevent="
                  changeAmount({
                    productId: item.product_id,
                    action: 'INCREMENT_AMOUNT_CARD'
                  })
                  "></span>
              </p>
              <strong><template v-if="item.price.position && item.price.position === 'start'">{{ item.price.currency
                  }}</template>
                {{ item.priceCalc }}
                <template v-if="item.price.position && item.price.position === 'end'">{{ item.price.currency
                  }}</template>
                <template v-else>₽</template></strong>
            </div>
          </div>
          <div class="footer" v-if="card.length">
            <div class="cart-result">
              <span>{{ $t("orders.total_price") }}</span>
              <strong><template v-if="totalPrice.position === 'start'">{{
                  totalPrice.currency
                  }}</template>
                {{ totalPrice.price }}
                <template v-if="totalPrice.position === 'end'">{{
                  totalPrice.currency
                  }}</template>
                <template v-else>₽</template></strong>
            </div>
            <br />
            <a href="#" @click.prevent="createOrder" v-if="!isOrderProcessing" class="button">{{ $t("shop.create_order")
              }}</a>
          </div>
        </div>
        <div class="product-payment" v-if="isOrderProcessing">
          <form>
            <div class="cols">
              <div class="col">
                <p>
                  <strong>{{ $t("shop.order_processing") }}</strong><br />
                  {{ $t("shop.order_enter") }}
                </p>
              </div>
              <div class="col"></div>
              <div class="col">
                <div class="field-row">
                  <label for="seat_number">{{
                    $t("planes.seat_number")
                    }}</label>
                  <input @input="toUpperCase" type="text" name="seat_number" id="seat_number" v-model="form.seat_number"
                    :class="{ is_invalid: errors.seat_number }" />
                  <p class="error-description" v-if="errors.seat_number">
                    {{ $t("planes.seat_number_error") }}
                  </p>
                </div>

                <div class="field-row" v-if="send_check">
                  <label for="email">{{ $t("planes.email") }}</label>
                  <input type="email" name="email" id="email" v-model="form.email"
                    :class="{ is_invalid: errors.email }" />
                  <p class="error-description" v-if="errors.email">
                    {{ $t("planes.email_error") }}
                  </p>
                </div>
                <div class="field-cor-row">
                  <label class="input-checkbox">
                    <input type="checkbox" v-model="send_check" @click="clearErrors" />
                    <span class="checkmark"></span>
                  </label>
                  {{ $t("planes.send_check") }}
                </div>
              </div>
              <div class="col"></div>
              <div class="col">
                <div class="buttons">
                  <a href="#" class="submit cancel" @click.prevent="cancelOrderAction">
                    <span>{{ $t("shop.cancel") }}</span></a>
                  <a href="#" class="submit border-none" :class="{ active: formInputsValidate }"
                    @click.prevent="sendOrder">
                    <span>{{ $t("shop.confirm") }}</span></a>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <success-payment-shop v-if="shopPaidSuccess" :orderNumber="orderNumber"></success-payment-shop>
      <isHave18 v-if="!isHave18 && isHaveAlcohol" @sendOrder="sendOrder" @cancelOrder="cancelOrderAction" />
    </section>
  </main>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SuccessPaymentShop from "@/components/popup/SuccessPaymentShop";
import isHave18 from "@/components/popup/isHave18question";

export default {
  name: "Shop",
  components: {
    SuccessPaymentShop,
    isHave18
  },
  title() {
    return this.$t("pages.shop");
  },
  data: () => {
    return {
      send_check: true,
      form: {
        seat_number: "",
        email: "",
        code: "",
        paymentType: 1
      },
      errors: {
        phone: false,
        email: false,
        code: false,
        seat_number: false
      }
    };
  },
  computed: {
    ...mapState("shop", {
      catalog: state => state.index,
      card: state => state.card,
      shopPaidSuccess: state => state.shopPaidSuccess,
      isHave18: state => state.isHave18,
      isHaveAlcohol: state => state.isHaveAlcohol
    }),
    ...mapState("order", {
      isOrderProcessing: state => state.isOrderProcessing,
      skyShop: state => state.skyShop,
      orderNumber: state => state.orderNumber
    }),
    ...mapState("user", {
      user: state => state.user
    }),
    formInputsValidate() {
      let seatNumberLength = this.form.seat_number.length >= 2;
      let mailLength = true;
      if (this.send_check) {
        mailLength = this.form.email.length >= 5;
      }
      return seatNumberLength && mailLength;
    },
    seatNumberValidate() {
      let seatNumberValidator = new RegExp(/^[0-9]{1,2}[ABCDEFGHKL]$/);
      let seatNumber = seatNumberValidator.test(this.form.seat_number);
      if (!seatNumber) {
        return false;
      }
      return true;
    },
    emailValidate() {
      let emailValidator = new RegExp(
        "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"
      );
      let validEmail = emailValidator.test(this.form.email);
      if (!validEmail) {
        return false;
      }
      return true;
    },
    phoneValidate() {
      let validator = new RegExp("^[0-9]+$");
      let test = validator.test(this.form.phone);
      if (!test || this.form.phone.length < 10) {
        return false;
      }

      return true;
    },
    codeValidate() {
      return this.form.code.length >= 3;
    },
    computedCard() {
      let result = [];
      if (this.card.length === 0) {
        return result;
      }
      for (let i = 0; i < this.card.length; i++) {
        for (let iC = 0; iC < this.catalog.length; iC++) {
          let item = this.catalog[iC].goods.find(
            it => it.product_id === this.card[i].productId
          );
          if (item) {
            result.push({
              ...item,
              category: this.card[i].category,
              amount: this.card[i].amount,
              priceCalc: this.card[i].amount * item.price.amount
            });
          }
        }
      }
      return result;
    },
    totalPrice() {
      let position = this.computedCard[0].price.position || "end";
      let currency =
        this.computedCard[0].price.currency === "rub"
          ? "₽"
          : this.computedCard[0].price.currency;

      const totalPrice = {
        price: this.computedCard.reduce((a, b) => a + b.priceCalc, 0),
        position,
        currency
      };

      this.setEntity(["shopPaidInfo", totalPrice]);

      return totalPrice;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.actionIndex();
    });
  },
  methods: {
    ...mapActions("order", {
      cancelOrder: "cancelOrder",
      createOrderAction: "setOrderSkyShop",
      updateOrderAction: "updateOrderState"
    }),
    ...mapActions("shop", {
      actionIndex: "index",
      changeAmount: "changeAmount"
    }),
    ...mapMutations("shop", {
      setEntity: "SET_ENTITY"
    }),
    ...mapMutations("order", {
      deleteOrder: "DELETE_ENTITY",
      setOrderEntity: "SET_ENTITY"
    }),
    toUpperCase() {
      this.form.seat_number = this.form.seat_number.toUpperCase();
    },
    clearCard() {
      this.setEntity(["card", []]);
      this.setOrderEntity(["isOrderProcessing", false]);
    },

    async createOrder() {
      this.setOrderEntity(["isOrderProcessing", true]);
    },
    async sendOrder() {
      this.errors.seat_number = !this.seatNumberValidate;
      if (this.send_check) {
        this.errors.email = !(this.form.email.length > 1 && this.emailValidate);
      }

      // this.errors.phone = !(this.form.phone.length > 1 && this.phoneValidate);
      // this.errors.code = !(this.form.code.length > 1 && this.codeValidate);
      if (!this.errors.email && !this.errors.seat_number) {
        try {
          let items = [];
          for (let i = 0; i < this.computedCard.length; i++) {
            items.push({
              product_id: parseInt(this.computedCard[i].product_id),
              qty: this.computedCard[i].amount
            });
          }
          // guid and pin
          const order = {
            seat: this.form.seat_number,
            items: items
          };

          if (this.send_check) {
            order.email = this.form.email;
          }

          if (!this.isHave18) {
            this.computedCard.forEach(element => {
              if (element.category === "alcoholic_beverages") {
                this.setEntity(["isHaveAlcohol", true]);
              }
            });
            if (this.isHaveAlcohol) {
              return;
            }
          }

          await this.createOrderAction(order);

          this.setEntity(["card", []]);

          this.setEntity(["shopPaidSuccess", true]);

          this.setEntity(["isHave18", false]);
          this.setEntity(["isHaveAlcohol", false]);
        } catch (e) {
          this.errors.code = true;
          this.setEntity(["isHave18", false]);
          this.setEntity(["isHaveAlcohol", false]);
        }
      }
    },
    clearErrors() {
      this.errors = {
        phone: false,
        email: false,
        code: false,
        seat_number: false
      };
    },
    async cancelOrderAction() {
      this.form.seat_number = "";
      this.form.email = "";

      this.setOrderEntity(["isOrderProcessing", false]);
      this.clearErrors();

    }
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.4;
}

.active {
  background-color: #d0112b !important;
}

.border-none {
  border: none;
}
</style>
